<template>
	<div class="main-wrapper">
		<CRow>
			<CCol
				md="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4>
					General info
				</h4>
				<div class="marketing-category-id">
					<span class="typo-caption-2 mr-1">Customer ID:</span>
					<span class="typo-caption-2 font-weight-bold">
						{{ isEdit ? $route.params.id : 'Generate after saving' }}
					</span>
				</div>
			</CCol>
			<CCol md="6">
				<CInput
					v-model="$v.formData.firstName.$model"
					:is-valid="!$v.formData.firstName.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="First name*"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model="$v.formData.lastName.$model"
					:is-valid="!$v.formData.lastName.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Last name*"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model="$v.formData.email.$model"
					:is-valid="!$v.formData.email.$error && null"
					:invalid-feedback="emailErrors"
					:disabled="isEdit"
					label="Email address*"
				/>
			</CCol>
			<CCol md="6">
				<div class="form-group">
					<BaseInputDate
						v-model="formData.birthdate"
						:max-date="maxActiveDate"
						label="Birth of date"
						placeholder="Select date"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
				</div>
			</CCol>
			<CCol v-if="isEdit" md="6" />
			<CCol
				v-if="isEdit"
				md="6"
			>
				<CButton
					v-if="isPending"
					:disabled="disabledResend"
					class="btn-resend"
					color="secondary text-nowrap"
					@click="handleResend"
				>
					Resend verification link
				</CButton>
			</CCol>
			<CCol
				v-if="!isEdit"
				md="6"
			>
				<CInput
					v-model="$v.formData.password.$model"
					:is-valid="!$v.formData.password.$error && null"
					:invalid-feedback="passwordErrors"
					type="password"
					label="Password*"
					description="Minimum 8 characters, must contain at least one lowercase letter (a - z), one uppercase letter (A-Z) and one digit (0-9)."
				/>
			</CCol>
			<CCol
				v-if="isEdit"
				md="6"
			>
				<CInput
					v-model="$v.formData.provider.$model"
					:is-valid="!$v.formData.provider.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:disabled="isEdit"
					label="Provider*"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model="$v.formData.phone.$model"
					:is-valid="!$v.formData.phone.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Phone*"
				/>
			</CCol>
			<CCol md="6">
				<BaseDropDown
					v-model="formData.store"
					:options="WAREHOUSE_OPTIONS"
					label="name"
					label-drop-down="Store ID"
					placeholder="Select store"
				/>
			</CCol>
			<CCol md="6">
				<BaseDropDown
					v-model="$v.formData.customerGroup.$model"
					:options="CUSTOMER_GROUP_OPTIONS"
					:is-valid="!$v.formData.customerGroup.$error"
					:invalid-feedback="$t('global.error.required')"
					label="name"
					label-drop-down="Customer group*"
					placeholder="Select customer group"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model="formData.staffId"
					label="Staff ID"
				/>
			</CCol>
			<CCol
				v-if="isEdit"
				md="12"
				class="d-flex align-items-center mt-3"
			>
				<div class="label">
					Account status
				</div>
				<CBadge
					v-if="isPending"
					class="ml-auto"
					color="hypertext"
				>
					Pending
				</CBadge>
				<div v-else class="form-group-switch">
					<CSwitch
						:checked.sync="formData.isActive"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span>{{ formData.isActive ? 'Active' : 'Inactive' }}</span>
				</div>
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:is-edit="isEdit"
			:disabled-confirm="isSubmitting"
			@onConfirm="handleConfirm"
			@onCancel="handleCancel"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, requiredUnless } from 'vuelidate/lib/validators';
import BaseDropDown from '@/components/BaseDropDown.vue';
import { scrollToTop, dateFormat } from '../assets/js/helpers';
import { passwordFormat } from '../assets/js/validators';
import { CUSTOMER_STATUS } from '../enums/customers';

export default {
	name: 'FormCustomer',
	components: {
		BaseDropDown,
	},
	validations: {
		formData: {
			firstName: {
				required,
			},
			lastName: {
				required,
			},
			email: {
				required,
				email,
			},
			phone: {
				required,
			},
			password: {
				required: requiredUnless(function requiredCheck() {
					return this.isEdit;
				}),
				passwordFormat(value) {
					return this.isEdit ? true : passwordFormat(value);
				},
			},
			customerGroup: {
				required,
			},
			provider: {
			},
		},
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		firstName: {
			type: String,
			default: null,
		},
		lastName: {
			type: String,
			default: null,
		},
		email: {
			type: String,
			default: null,
		},
		phone: {
			type: String,
			default: null,
		},
		store: {
			type: Object,
			default: null,
		},
		staffId: {
			type: String,
			default: null,
		},
		customerGroup: {
			type: Object,
			default: null,
		},
		customerStatus: {
			type: String,
			default: null,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		provider: {
			type: String,
			default: '-',
		},
		birthdate: {
			type: [String, Date],
			default: null,
		},
	},
	data() {
		return {
			CUSTOMER_STATUS,
			timer: null,
			isChangePassword: false,
			disabledResend: false,
			maxActiveDate: new Date(),
			formData: {
				firstName: null,
				lastName: null,
				email: null,
				password: null,
				phone: null,
				store: null,
				staffId: null,
				customerGroup: null,
				isActive: null,
				prodvider: null,
				birthdate: null,
			},
		};
	},
	computed: {
		...mapGetters({
			WAREHOUSE_OPTIONS: 'warehouses/warehouseOptions',
			CUSTOMER_GROUP_OPTIONS: 'customerGroups/customerGroupOptions',
		}),
		isPending() {
			return this.customerStatus === CUSTOMER_STATUS.PENDING;
		},
		emailErrors() {
			if (!this.$v.formData.email.required) {
				return 'Required field';
			}

			if (!this.$v.formData.email.email) {
				return 'Invalid email format';
			}

			return null;
		},
		passwordErrors() {
			if (!this.$v.formData.password.required) {
				return 'Required field';
			}

			if (!this.$v.formData.password.passwordFormat) {
				return 'Invalid password format';
			}

			return null;
		},
	},
	async created() {
		this.formData = {
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			password: this.password,
			phone: this.phone,
			store: this.store,
			customerGroup: !!this.customerGroup && { name: this.customerGroup.name, value: this.customerGroup.id },
			isActive: this.isActive,
			staffId: this.staffId,
			provider: this.provider,
			birthdate: this.birthdate,
		};
	},
	methods: {
		handleResend() {
			this.$emit('onResendVerificationLink');
			this.disabledResend = true;
		},
		async handleConfirm() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}

			const params = {
				first_name: this.formData.firstName,
				last_name: this.formData.lastName,
				phone_number: this.formData.phone,
				password: this.formData.password,
				email: this.formData.email,
				staff_id: this.formData.staffId,
				branch_id: this.formData?.store?.value,
				customer_group_id: this.formData?.customerGroup?.value,
				is_active: !!this.formData?.isActive,
				birthdate: dateFormat(this.formData?.birthdate),
			};

			this.$emit('onConfirm', params);
		},
		handleCancel() {
			this.$router.push({ name: 'CustomerList' });
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-resend {
		margin-top: rem(32);
	}

	.form-group-switch {
		margin-left: rem(64);
	}
</style>
